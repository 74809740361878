input:checked ~ .css-1idziu6 {
    display: block;
    color: #1034A6;
}

input:disabled ~ .css-1idziu6 {
    display: block;
    color: #CACACA;
}

input:disabled ~ .css-1kxd8do {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    
    width: 24px;
    height: 24px;
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    margin-right: 8px;
    border-radius: 4px;
    color: #CACACA;
    width: 24px;
    height: 24px;
    display: none;
}input:checked ~ .css-1orewpb {
    display: block;
    color: #075FD2;
}

input:focus ~ .css-1orewpb {
    color: primary;
    background-color: none !important ;
}

input:checked ~ .css-1fj6ok6 {
    display: block;
    color: #075FD2;
}

input:focus ~ .css-1orewpb {
    color: primary;
    background-color: none !important ;
}
