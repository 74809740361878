.fadein-text {
  animation: fadeIn 4s linear;
}

.link-fadein-text {
  animation: fadeIn 3s linear 2s forwards;
  opacity: 0;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


