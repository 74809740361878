.next {
  display: none;
}

.next-enter {
  display: block;
  transform: translateX(150%);
}
.next-enter-done {
  display: block;
  /* transform: translateX(0%); */
  transition: transform 150ms ease-out;
}
.next-exit {
  display: block;
  /* transform: translateX(0%); */
}
.next-exit-done {
  display: block;
  transform: translateX(-150%);
  transition: transform 150ms ease-out;
}

.return {
  display: none;
}
.return-enter {
  display: block;
  transform: translateX(-150%);
}
.return-enter-done {
  display: block;
  /* transform: translateX(0%); */
  transition: transform 150ms ease-out;
}
.return-exit {
  display: block;
  /* transform: translateX(0%); */
}
.return-exit-done {
  display: block;
  transform: translateX(150%);
  transition: transform 150ms ease-out;
}

.progress {
  transition: width 2s;
  background-image: none !important;
}
