* {
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar {
  display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


html {
  scroll-behavior: smooth;
  font-size: 16px;
}

.symptombox {
  display: none;
}

input[type="checkbox"].symptombox {
  opacity: 0;
}

input:checked {
  color: #1034A6 !important;
}

button:focus {
  outline: none;
}

h1, h2, h3, h4, h5 {
  font-family: "Mitr", "Nunito";
}

i.huge.icon, i.huge.icons {
  line-height: 1;
  vertical-align: middle;
  font-size: 3.5em;
  transition: .7s;
  transform: rotate(0deg);
}

i.big.icon, i.big.icons {
  line-height: 1;
  vertical-align: middle;
  font-size: 3.3em;
  transform: rotate(144deg);
  transition: .7s;
}

body {
  line-height: normal;
}
@font-face {
  font-family: "SukhumvitSet-Medium";
  font-style: normal;
  src: url("Fonts/SukhumvitSet-Medium.ttf");
}

@font-face {
  font-family: "SukhumvitSet-SemiBold";
  font-style: normal;
  src: url("Fonts/SukhumvitSet-SemiBold.ttf");
}

@font-face {
  font-family: "SukhumvitSet-Bold";
  font-style: normal;
  src: url("Fonts/SukhumvitSet-Bold.ttf");
}

@font-face {
  font-family: "Roboto-Regular";
  src: url("Fonts/Roboto-Regular.ttf");
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("Fonts/Roboto-Medium.ttf");
}
