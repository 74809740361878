.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  background: #fff;
}

.react-datepicker__header {
  text-align: center;
  background-color: #fff;
  border-bottom: 0px solid #aeaeae !important;
  border-top-left-radius: 0.3rem;
  padding: 8px 0;
  position: relative;
}

.react-datepicker__day--selected,
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range,
.react-datepicker__day--in-range,
.react-datepicker__day--keyboard-selected {
  border-radius: 50% !important;
  background-color: rgba(16, 52, 166, 0.12) !important;
  color: #000 !important;
}

.react-datepicker__day--range-start {
  background-color: #1034a6 !important;
  border-radius: 50%;
  color: #fff !important;
}

.react-datepicker__day--range-end {
    background-color: #1034a6 !important;
    border-radius: 50%;
    color: #fff !important;
  }

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 50% !important;
  background-color: #f0f0f0;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #000;
  display: inline-block;
  width: 2rem !important;
  line-height: 2rem !important;
  text-align: center;
  margin: 0rem;
  height: 32px;
}


